import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Navbar from '../Navbar/Navbar';
import Footer from "../Footer/Footer";
import './LandingPage.css';
import network from '../../assets/Network1.png'
import SneakPeak from "./SneakPeak";
import About from "./About";
import WhyTrustBlu from "./WhyTrustBlu";
import Reward from "./Reward";
import WaitlistPopup from "./WaitlistPopup";


const LandingPage = () => {
  const [visibility, setVisibility] = useState({
    badgeAndButton: false,
    paragraph: false,
    button: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisibility((prev) => ({ ...prev, badgeAndButton: true }));
    }, 3000);
    setTimeout(() => {
      setVisibility((prev) => ({ ...prev, paragraph: true }));
    }, 1000);
    setTimeout(() => {
      setVisibility((prev) => ({ ...prev, button: true }));
    }, 2000);
  }, []);

  return (
    <div className="landing-page">
      <Navbar />
  
      {/* Hero Section */}
      <main id="Home" className="section1 text-center flex flex-row items-center justify-center min-h-screen relative -mt-40 md:mt-20">
        <div className="container mx-auto px-4 grid-background flex flex-col items-center justify-center">
          {/* Badge and Button */}
          {/* <motion.div
            style={{ visibility: visibility.badgeAndButton ? 'visible' : 'hidden' }}
            initial={{ opacity: 0, x: 20 }}
            animate={visibility.badgeAndButton ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <div className="custom-badge inline-flex items-center justify-center bg-white p-1 rounded-full border-2 border-black">
              <span className="badge bg-customBlue text-white px-3 py-1 rounded-full">New</span>
              <span className="badge-text mx-2">Be a part of something meaningful</span>
            </div>
          </motion.div> */}
  
          {/* Title Animation */}
          <motion.h1
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold bg-gradient-to-l from-blue-500 to-red-500 text-transparent bg-clip-text leading-tight mt-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Incentivizing <br /> Blood Donation
          </motion.h1>
  
          {/* Paragraph Animation */}
          <motion.p
            style={{ visibility: visibility.paragraph ? 'visible' : 'hidden' }}
            className="lead text-gray-500 mt-4 text-base sm:text-lg md:text-xl lg:text-2xl max-w-3xl px-4 md:px-0"
            initial={{ opacity: 0, y: 20 }}
            animate={visibility.paragraph ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            Accelerating blood donations with incentives&nbsp;  
            <span className="hidden lg:inline"><br/></span> 
            and a community-first approach
          </motion.p>
  
          {/* Button Animation */}
          <motion.div
            style={{ visibility: visibility.button ? 'visible' : 'hidden' }}
            className="mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={visibility.button ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            <div className="action-buttons">
              <button
                onClick={openModal}
                className="btn bg-customBlue text-white py-2 px-6 text-base sm:text-lg mr-4 rounded-lg focus:outline-none hover:bg-customRed transition-colors"
              >
                Join waitlist
              </button>
            </div>
          </motion.div>
        </div>
      </main>

      <section id="About">
        <About />
      </section>

      <section id="Why TrustBlu">
        <WhyTrustBlu />
      </section>
  
      <section className="hero-section bg-white flex items-center justify-center px-4 md:mt-10">
  <div className="container mx-auto flex flex-col md:flex-row items-start gap-10 px-4">

    <div className="flex flex-col items-start gap-6 w-full">
      {/* Icon and Title */}
      <div className="flex items-center gap-4">
        <div className="icon-container">
          <img
            src={network}
            alt="Icon"
            className="w-12 h-12"
          />
        </div>
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight text-customBlue">
          A Glimpse of TrustBlu.
        </h2>
      </div>

      {/* Description */}
      <div className="text-container w-full">
        <p className="text-base sm:text-lg text-gray-600 text-justify mb-5">
          Explore how TrustBlu can make a difference. Together we can motivate people to create a world where every contribution is valued, and every effort is celebrated.
        </p>
      </div>
    </div>

  </div>
</section>

  
      <SneakPeak />
      
      
  
      <section id="Reward">
        <Reward />
      </section>
  
      <Footer />

      <WaitlistPopup isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
  
};

export default LandingPage;
