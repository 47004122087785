import React from 'react';

const TaskSection = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Active Problems</h2>
      {/* Add search and filter options here */}
      <div className="overflow-y-auto max-h-[400px]">
        {Array.from({ length: 6 }, (_, index) => (
          <div key={index} className="p-4 mb-2 border rounded-lg flex justify-between items-center bg-gray-50">
            <span>Problem {index + 1}</span>
            <span className="text-green-600 font-bold">$10-20 USD</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskSection;
