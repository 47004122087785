import React from 'react';

const WhatsNewSection = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">What's New</h2>
      <ul>
        {['Kratos', 'Kirito', 'Atin'].map((name, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
            <span>{index + 1}. {name}</span>
            <span className="font-bold text-purple-600">{1000 - index * 100}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhatsNewSection;
