import React from 'react';
import { motion } from 'framer-motion';

const AppointmentsSection = () => {
  const appointmentDetails = {
    title: 'Next Appointment',
    date: 'October 10, 2024',
    time: '10:30 AM',
    location: 'Downtown Health Center',
  };

  return (
    <motion.div
      className="p-4 bg-blue-300 text-white rounded-lg shadow-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <h2 className="text-lg font-bold mb-4">Appointments</h2>
      <p className="mb-4">Click to view all appointments</p>
      <button className="bg-blue-600 px-4 py-2 rounded-lg mb-6">Get Access</button>

      {/* Animated Appointment Details with Continuous Pulsing Effect */}
      <motion.div
        className="p-4 bg-blue-800 text-white rounded-lg shadow-md mt-6"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{
          opacity: [1, 0.8, 1],
          scale: [1, 1.05, 1],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          repeatType: 'mirror',
          ease: 'easeInOut',
        }}
      >
        <h3 className="text-lg font-bold mb-2">{appointmentDetails.title}</h3>
        <p className="mb-1">
          <span className="font-semibold">Date: </span>{appointmentDetails.date}
        </p>
        <p className="mb-1">
          <span className="font-semibold">Time: </span>{appointmentDetails.time}
        </p>
        <p>
          <span className="font-semibold">Location: </span>{appointmentDetails.location}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default AppointmentsSection;
