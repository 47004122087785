import React from 'react';

const DurationSection = () => {
  const currentStreak = 5;
  const weekDays = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];
  const timer = "Next Donation in 16:54:13";

  return (
    <div className="p-4 bg-white rounded-lg shadow-md w-full">
      <div className="flex justify-between">
        <div>
          <h2 className="text-2xl font-bold text-blue-700">{currentStreak} </h2>
          <p className="text-sm text-gray-500">Current Streak</p>
        </div>
        <p className="text-sm text-gray-400">Add to Calender</p>
      </div>
      <div className="flex items-center justify-between mt-4 bg-blue-200 p-4 rounded-lg">
        {weekDays.map((day, index) => (
          <div
            key={index}
            className={`w-10 h-8 rounded-full flex items-center justify-center mx-0.5 ${
              index < currentStreak ? 'bg-blue-400 text-white' : 'bg-gray-200 text-gray-500'
            }`}
          >
            {index === currentStreak - 1 ? (
              <span className="text-xl">🕒</span> // This represents the clock icon for the current day
            ) : (
              <span className="text-sm">{day}</span>
            )}
          </div>
        ))}
      </div>
      <div className="mt-4 text-center">
        <span className="bg-gray-100 text-gray-600 py-1 px-3 rounded-full text-sm">
          {timer}
        </span>
      </div>
    </div>
  );
};

export default DurationSection;
