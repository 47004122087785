import React from 'react';
import ProfileSection from './ProfileSection';
import TaskSection from './TaskSection';
import DurationSection from './DurationSection';
import AppointmentsSection from './AppointmentsSection';
import WhatsNewSection from './WhatsNew';
import AboutAnimation from '../Landing/AboutAnimation';

import Navbar from './Navbar';
import Footer from '../Footer/Footer';

const Dashboard = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Main Dashboard Content */}
      <div className="flex-grow container mx-auto px-6 py-10">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Left Side */}
          <div className="lg:col-span-2 space-y-4">
            <ProfileSection />
            <TaskSection />
          </div>

          {/* Right Side */}
          <div className="space-y-4">
            <DurationSection />
            <AppointmentsSection />
            <WhatsNewSection />
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Dashboard;
