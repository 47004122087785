import React from 'react';
import { motion } from 'framer-motion';
import image1 from '../../assets/NFT2.webp';
import image2 from '../../assets/NFT3.webp';
import image3 from '../../assets/NFT4.webp';
import image4 from '../../assets/NFT5.webp';

const images = [image1, image2, image3, image4];

const ProfileSection = () => {
  return (
    <div className="relative w-full h-64 shadow-lg mb-20">
    <div className="relative w-full h-64 shadow-lg mb-20 overflow-hidden">
      {/* Background Images with Framer Motion Scroll Effect */}
      <motion.div
        className="absolute inset-0 h-full flex items-center"
        animate={{ x: [0, -2000] }}
        transition={{
          repeat: Infinity,
          duration: 40,
          ease: "linear",
        }}
      >
        {[...images, ...images, ...images].map((imageUrl, index) => (
          <div key={index} className="flex-shrink-0 w-64 h-full p-2">
            <img
              src={imageUrl}
              alt={`Background ${index + 1}`}
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </motion.div>
      </div>

      {/* Profile Card */}
      <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2 overflow-visible bg-gradient-to-r from-red-200 to-blue-200 p-6 rounded-2xl shadow-lg flex items-center space-x-6 max-w-4xl w-[95%] h-[50%]">
        {/* Profile Image */}
        <div className="w-20 h-20 rounded-xl overflow-hidden shadow-md">
          <img
            src="https://via.placeholder.com/150"
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Profile Details */}
        <div className="flex flex-col space-y-1">
          <div className="text-2xl font-semibold text-gray-900">Alec Thompson</div>
          <div className="text-gray-600 text-sm">CEO / Co-Founder</div>
        </div>

        {/* Social Media Icons */}
        <div className="flex space-x-3 ml-auto">
          <a
            href="#"
            className="bg-blue-600 p-2 rounded-md text-white shadow-md"
            aria-label="Facebook"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="#"
            className="bg-blue-400 p-2 rounded-md text-white shadow-md"
            aria-label="Twitter"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="#"
            className="bg-gray-800 p-2 rounded-md text-white shadow-md"
            aria-label="GitHub"
          >
            <i className="fab fa-github"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
